import { useTranslation } from 'react-i18next';
import Text from '@gym-atoms/Text/Text';
import Button, { ButtonSizes } from '@gym-atoms/Button/Button';
import Logo from '@gym-assets/images/empty-state.svg';
import API from '@aws-amplify/api';
import { addStaffAccount, provisionMindbodyStaffAccount } from '@gym-graphql/mutations';
import { ProvisionStaffAccountInput, StaffAccountInput } from '@gym-src/API';
import { useAppDispatch, useAppSelector } from '@gym-redux/store';
import { fetchGymChains } from '@gym-redux/slices/gymChainSlice';
import { useRef, useState } from 'react';
import Alert, { AlertType, createAlert } from '@gym-atoms/Alert/Alert';
import { UserRole } from '@gym-particles/types/User';

const ProvisionUserPage = (props: ProvisionUserPageProps) => {
  const { t } = useTranslation();
  const userRole = useAppSelector((state) => state.user.userRole);
  const dispatch = useAppDispatch();
  const userLanguage = useAppSelector((state) => state.user.language);
  const alertRef = useRef<AlertType>(null);

  const userId = useAppSelector((state) => state.user.userId);

  const gymChainInfo = useAppSelector((state) =>
    state.gymChain.items.find((gymChain) => gymChain.id === props.siteId)
  );

  const [resendLoading, setResendLoading] = useState(false);
  const [provisionLoading, setProvisionLoading] = useState(false);

  const resendCode = async () => {
    if (!gymChainInfo) {
      console.log('Gym chain info not available');
      createAlert(alertRef, {
        content: t('PROVISION_USER_PAGE.EMAIL_RESEND_FAILURE_TEXT'),
        severity: 'error'
      });
      return;
    }

    try {
      const provisionInput: ProvisionStaffAccountInput = {
        customerSiteId: gymChainInfo.customerSiteId || '-1',
        gymChainName: gymChainInfo.name,
        email: gymChainInfo.customerEmail || '',
        ownerName: gymChainInfo.contactPerson || '',
        language: userLanguage.toLowerCase() || 'en'
      };

      setResendLoading(true);

      await API.graphql({
        query: provisionMindbodyStaffAccount,
        variables: {
          input: provisionInput
        }
      });

      createAlert(alertRef, {
        content: t('PROVISION_USER_PAGE.EMAIL_RESEND_SUCCESS_TEXT')
      });
    } catch (err) {
      console.error('failed to get activation link: ', err);
      createAlert(alertRef, {
        content: t('PROVISION_USER_PAGE.EMAIL_RESEND_FAILURE_TEXT'),
        severity: 'error'
      });
    } finally {
      setResendLoading(false);
    }
  };

  const provisionUser = async () => {
    try {
      const createStaffAccountInput: StaffAccountInput = {
        siteId: props.siteId,
        customerSiteId: gymChainInfo?.customerSiteId || '-1',
        createdBy: userId
      };

      setProvisionLoading(true);

      await API.graphql({
        query: addStaffAccount,
        variables: {
          input: createStaffAccountInput
        }
      });

      dispatch(
        fetchGymChains({
          offset: 0,
          pageSize: 0,
          sortField: 'id',
          sortOrder: 1,
          userId: userRole === UserRole.SYSTEM_ADMIN ? 0 : userId
        })
      );
    } catch (err) {
      console.error('failed to add the staff account: ', err);
      createAlert(alertRef, {
        content: t('PROVISION_USER_PAGE.PROVISION_ACCOUNT_FAILURE_TEXT'),
        severity: 'error'
      });
    } finally {
      setProvisionLoading(false);
    }
  };

  return (
    <>
      <Alert refProp={alertRef} />
      <div className="text-center py40">
        <div className="input-mb20 mb50 mt50">
          <img src={Logo} alt={'empty state'} width={200} height={200} />
        </div>
        <Text>{t('PROVISION_USER_PAGE.TEXT_PART_ONE')}</Text>
        <Text className="mb20">{t('PROVISION_USER_PAGE.TEXT_PART_TWO')}</Text>
        <div className="mt20 mb20 d-flex align-items-center justify-content-center">
          {/* <div className="pr10 pl10">
            <Button
              label={t('PROVISION_USER_PAGE.RESEND_BUTTON_TEXT')}
              size={ButtonSizes.large}
              icon={resendLoading ? 'pi-spinner pi-spin' : ''}
              onClick={resendCode}
            />
          </div> */}
          <div className="pr10 pl10">
            <Button
              label={t('PROVISION_USER_PAGE.PROVISION_BUTTON_TEXT')}
              size={ButtonSizes.large}
              icon={provisionLoading ? 'pi-spinner pi-spin' : ''}
              onClick={provisionUser}
            />
          </div>
        </div>
        <br></br>
      </div>
    </>
  );
};

export default ProvisionUserPage;

export interface ProvisionUserPageProps {
  /** Site ID of the gym chain */
  siteId: number;
}
