import { FormEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { API } from 'aws-amplify';
import { useForm } from 'react-hook-form';
import Checkbox from '@gym-atoms/Checkbox/Checkbox';
import Dropdown, { DropdownChangeEvent } from '@gym-atoms/Dropdown/Dropdown';
import Upload from '@gym-atoms/Upload/Upload';
import Input from '@gym-atoms/Input/Input';
import Button, { ButtonSizes, ButtonVariants } from '@gym-atoms/Button/Button';
import { GymChain } from '@gym-particles/types/models.d';
import { UserRole } from '@gym-particles/types/User';
import {
  addGymChains,
  mindbodyNightlySync,
  mindbodyNightlySyncStatus,
  modifyGymChain
} from '@gym-graphql/mutations';
import {
  WebGymChainInput,
  ImportSiteResponse,
  WebGymChain,
  SodvinImportedLocation,
  MindbodyNightlySyncResponse,
  MindbodyNightlySyncStatusResponse
} from '@gym-src/API';
import { importSite, importSodvinLocation, uploadImageUrlResolver } from '@gym-graphql/queries';
import Text from '@gym-atoms/Text/Text';
import { useAppDispatch, useAppSelector } from '@gym-redux/store';
import * as Validator from '@gym-particles/validations/Validator';
import DialogBox, { DialogBoxVariants } from '@gym-atoms/Dialog/DialogBox';
import { fetchSidebarGymChains } from '@gym-redux/slices/gymChainSlice';
import base64toFile from '@gym-particles/base64ToFile';
import axios from 'axios';
import Alert, { AlertType, createAlert } from '@gym-atoms/Alert/Alert';

const GymChainInfoTemplate = (props: GymChainInfoTemplateProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [imgUploadStatus, setImgUploadStatus] = useState('NONE');
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [importError, setImportError] = useState(false);
  const [alreadyImportedError, setAlreadyImportedError] = useState(false);
  const [importing, setImporting] = useState(false);
  const dispatch = useAppDispatch();
  const [failedDialogVisible, setFailedDialogVisible] = useState(false);
  const [dialogErrorMessage, setDialogErrorMessage] = useState<string | null>(null);
  const [isSodvinCompany, setIsSodvinCompany] = useState(props.gymChain?.isSodvinCompany || false);
  const [imported, setImported] = useState(false);
  const [notImportedError, setNotImportedError] = useState(false);
  const [importAPIValue, setImportAPIValue] = useState<number>();
  const userId = useAppSelector((state) => state.user.userId);
  const [isInserted, setIsInserted] = useState(false);
  const [allowConvertingAccessCards, setAllowConvertingAccessCards] = useState<boolean | undefined>(
    props.gymChain?.allowConvertingAccessCards || false
  );
  const [processSales, setProcessSale] = useState(props.gymChain?.processSales || false);
  const [processBookings, setProcessBookings] = useState(props.gymChain?.processBookings || false);
  const [processAppointments, setProcessAppointments] = useState(
    props.gymChain?.processAppointments || false
  );
  const [fetchAccountBalance, setFetchAccountBalance] = useState(
    props.gymChain?.fetchAccountBalance || false
  );
  const [syncInProgress, setSyncInProgress] = useState(false);
  const alertRef = useRef<AlertType>(null);
  const userRole = useAppSelector((state) => state.user.userRole);
  const userLanguage = useAppSelector((state) => state.user.language);
  const getTranslationKey = (e: string): string => {
    return props.type === 'new' ? 'ADD_NEW_GYM_CHAIN.' + e : 'MODIFY_GYM_CHAIN.' + e;
  };

  const dropDownItems = [
    {
      label: t(getTranslationKey('FORM_OPTION1')),
      value: 1
    },
    {
      label: t(getTranslationKey('FORM_OPTION3')),
      value: 3
    }
  ];
  const [importParams, setImportParams] = useState<{ provider: string; id: number }>({
    provider: 'mindbody',
    id: 1
  });

  const dropDownItemsChips = [
    { label: t(getTranslationKey('FORM_STATUS_DROPDOWN_ACTIVE')), value: 'active' },
    { label: t(getTranslationKey('FORM_STATUS_DROPDOWN_INACTIVE')), value: 'inactive' }
  ];

  const uploadHandler = (f: File | null) => {
    setValue('profilePic', f?.toString());
  };

  useEffect(() => {
    setImportParams({
      provider:
        props.gymChain?.providerId === 2
          ? 'sodvin'
          : props.gymChain?.providerId === 3
          ? 's30integration'
          : 'mindbody',
      id: props.gymChain?.providerId || 1
    });
    getMindbodyNightlySyncStatus();
    console.log(syncInProgress);
  }, [props.gymChain]);

  type FormInputs = {
    name: string;
    contactPerson: string;
    address: string;
    email: string;
    phoneNumber: string;
    supportPhoneNumber: string;
    maximumNegativeBalance: number;
    kisiApiKey: string;
    kisiNightlySyncApiKey: string;
    profilePic: string | null;
    status: string;
    providerId: number;
    customerSiteId: string;
    lastImportedDate: string | null;
    arxReferenceId?: string;
  };

  const defaultValues: FormInputs = {
    name: props.gymChain?.name || '',
    contactPerson: props.gymChain?.contactPerson || '',
    address: props.gymChain?.customerAddress || '',
    email: props.gymChain?.customerEmail || '',
    phoneNumber: props.gymChain?.phoneNumber || '',
    supportPhoneNumber: props.gymChain?.supportPhoneNumber || '',
    maximumNegativeBalance: props.gymChain?.maximumNegativeBalance || 0,
    kisiApiKey: props.gymChain?.kisiApiKey || '',
    kisiNightlySyncApiKey: props.gymChain?.kisiNightlySyncApiKey || '',
    profilePic: props.gymChain?.avatar || null,
    status: props.gymChain?.status || 'active',
    providerId: props.gymChain?.providerId || 2,
    customerSiteId: props.gymChain?.customerSiteId || '0',
    lastImportedDate: props.gymChain?.lastImportedDate || null,
    arxReferenceId: props.gymChain?.arxReferenceId || ''
  };

  const [formValues, setFormValues] = useState(defaultValues);

  const { register, handleSubmit, setValue, errors, reset } = useForm<FormInputs>({
    defaultValues: defaultValues
  });

  const ActionFailedDialog = () => {
    return (
      <div>
        <DialogBox
          variant={DialogBoxVariants.basic}
          dialogVisible={failedDialogVisible}
          onHideCallback={() => setFailedDialogVisible(false)}
          dialogHeader={t(getTranslationKey('FORM_FAILURE_DIALOG_HEADER'))}
          dialogFooter={
            <Button
              label={t(getTranslationKey('FORM_FAILURE_DIALOG_BUTTON'))}
              onClick={() => setFailedDialogVisible(false)}
            />
          }
          dialogDismissableMask={true}
          dialogClosable={false}
          content={
            <Text>
              {dialogErrorMessage
                ? dialogErrorMessage
                : t(getTranslationKey('FORM_FAILURE_DIALOG_BODY'))}
            </Text>
          }
        />
      </div>
    );
  };

  const getImageUrl = async (file: File) => {
    try {
      const response = (await API.graphql({
        query: uploadImageUrlResolver,
        variables: {
          imageName: file.name
        }
      })) as { data: { uploadImageUrlResolver: { uploadUrl: string; url: any } } };

      await axios.put(response.data.uploadImageUrlResolver.uploadUrl, file, {
        headers: new Headers({
          'Content-Type': 'image/*'
        })
      });

      setImgUploadStatus('SUCCESS');

      uploadHandler(response.data.uploadImageUrlResolver.url);
    } catch (err) {
      setImgUploadStatus('ERROR');
    }
  };

  const onSubmitFn = async (data: FormInputs) => {
    setAlreadyImportedError(false);
    if (
      (!imported && props.type === 'new' && isSodvinCompany) ||
      (!imported && props.type === 'new' && importParams.id === 1) ||
      (imported && props.type === 'new' && isSodvinCompany && !data.customerSiteId) ||
      (imported && props.type === 'new' && importParams.id === 1 && !data.customerSiteId)
    ) {
      setNotImportedError(true);
      setIsInserted(false);
      return;
    } else {
      setNotImportedError(false);
      setIsInserted(true);
    }
    if (props.type === 'modify' && props.gymChain) {
      // NOTE: The OR parts (... || 1) can be removed after type reconciliation.
      setIsInserted(true);
      const modifiedGymChain: WebGymChainInput = {
        id: props.gymChain.id,
        providerId: data.providerId,
        customerSiteId: props.gymChain.customerSiteId || '0',
        customerName: data.name.replace(/'/g, "''"),
        customerContactPerson: data.contactPerson.replace(/'/g, "''"),
        customerAddress: data.address.replace(/\n/g, ',').replace(/'/g, "''"),
        customerEmail: data.email.replace(/'/g, "''"),
        customerPhone: data.phoneNumber.replace(/'/g, "''"),
        supportPhone: '',
        maximumNegativeBalance: +data.maximumNegativeBalance || 0,
        kisiApiKey: data.kisiApiKey,
        kisiNightlySyncApiKey: data.kisiNightlySyncApiKey,
        status: data.status,
        lastModifiedBy: userId,
        createdBy: props.gymChain.createdBy || -1,
        imageUrl: data.profilePic,
        arxReferenceId: data.arxReferenceId?.replace(/'/g, "''") || '',
        providerConnected:
          props.gymChain?.providerConnected || data.providerId === 1 ? false : true,
        allowConvertingAccessCards: allowConvertingAccessCards || false,
        processSales: processSales || false,
        processBookings: processBookings || false,
        processAppointments: processAppointments || false,
        fetchAccountBalance: fetchAccountBalance || false
      };
      try {
        await API.graphql({
          query: modifyGymChain,
          variables: {
            input: modifiedGymChain
          }
        });

        dispatch(
          fetchSidebarGymChains({ userId: userRole === UserRole.SYSTEM_ADMIN ? 0 : userId })
        );
        history.push('/gymChains');
      } catch (error) {
        console.error('failed to modify new gym');
        setDialogErrorMessage(t(getTranslationKey('FORM_FAILURE_DIALOG_BODY')));
        setFailedDialogVisible(true);
      } finally {
        setIsInserted(false);
      }
    } else if (props.type === 'new') {
      const newGymChain: WebGymChainInput = {
        providerId: data.providerId,
        customerSiteId: data.customerSiteId,
        customerName: data.name.replace(/'/g, "''"),
        customerContactPerson: data.contactPerson.replace(/'/g, "''"),
        customerPhone: data.phoneNumber.replace(/'/g, "''"),
        supportPhone: '',
        maximumNegativeBalance: +data.maximumNegativeBalance || 0,
        customerEmail: data.email.replace(/'/g, "''"),
        kisiApiKey: data.kisiApiKey,
        kisiNightlySyncApiKey: data.kisiNightlySyncApiKey,
        customerAddress: data.address.replace(/\n/g, ',').replace(/'/g, "''"),
        status: data.status,
        createdBy: userId,
        lastModifiedBy: userId,
        imageUrl: data.profilePic,
        lastImportedDate: data.lastImportedDate,
        arxReferenceId: data.arxReferenceId?.replace(/'/g, "''") || '',
        providerConnected: data.providerId === 1 ? false : true,
        isSodvinCompany: isSodvinCompany,
        allowConvertingAccessCards: allowConvertingAccessCards || false,
        processSales: processSales || false,
        processBookings: processBookings || false,
        processAppointments: processAppointments || false,
        fetchAccountBalance: fetchAccountBalance || false
      };

      console.log(newGymChain);
      try {
        const addGymChainResponse = await (API.graphql({
          query: addGymChains,
          variables: {
            input: newGymChain
          }
        }) as Promise<{ data: { addGymChains: WebGymChain } }>);

        // To-Do: Remove the hardcoded Mindbody ID from other places as well.
        /* Commenting this since it's no longer needed. GYMXS-5361
        if (
          data.providerId === 1 &&
          addGymChainResponse.data.addGymChains.customerSiteId != null &&
          addGymChainResponse.data.addGymChains.customerSiteId != undefined
        ) {
          const provisionInput: ProvisionStaffAccountInput = {
            customerSiteId: addGymChainResponse.data.addGymChains.customerSiteId,
            gymChainName: data.name,
            email: data.email,
            ownerName: data.contactPerson,
            language: userLanguage.toLowerCase() || 'en'
          };

          try {
            await API.graphql({
              query: provisionMindbodyStaffAccount,
              variables: {
                input: provisionInput
              }
            });
          } catch (err) {
            console.error('failed to get activation link: ', err);
          }
        } */
        dispatch(
          fetchSidebarGymChains({ userId: userRole === UserRole.SYSTEM_ADMIN ? 0 : userId })
        );
        history.push('/gymChains');
      } catch (error) {
        console.error('failed add new gym');
        setDialogErrorMessage(t(getTranslationKey('FORM_FAILURE_DIALOG_BODY')));
        setFailedDialogVisible(true);
      } finally {
        setIsInserted(false);
      }
    }
  };

  const changeHandler = (e: FormEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.name as keyof FormInputs, e.currentTarget.value);
    setFormValues({ ...formValues, [e.currentTarget.name]: e.currentTarget.value });
  };

  const changeHandlerTA = (e: FormEvent<HTMLTextAreaElement>) => {
    setValue(e.currentTarget.name as keyof FormInputs, e.currentTarget.value);
    setFormValues({ ...formValues, [e.currentTarget.name]: e.currentTarget.value });
  };

  const statusDropdownHandler = (e: DropdownChangeEvent) => {
    setValue('status', e.value);
    setFormValues({ ...formValues, status: e.value });
  };

  const providerDropdownHandler = (e: DropdownChangeEvent) => {
    if (imported) {
      resetForm();
    } else {
      setValue('providerId', e.value);
      setFormValues({ ...formValues, providerId: e.value });
    }
    setImportParams({
      ...importParams,
      provider: e.value === 3 ? 's30integration' : 'mindbody',
      id: e.value
    });
  };

  const importSiteIdHandler = (e: FormEvent<HTMLInputElement>) => {
    if (props.type === 'new') {
      setImported(false);
    }
    setImportAPIValue(+e.currentTarget.value);
    setValue('customerSiteId', e.currentTarget.value);
    setFormValues({ ...formValues, [e.currentTarget.name]: e.currentTarget.value });
  };

  const handleImport = async (e: React.MouseEvent<Element>) => {
    e.preventDefault();
    setNotImportedError(false);
    setAlreadyImportedError(false);
    setImportError(false);
    setImporting(true);
    importParams.id === 1 ? await mindbodyImportHandler() : await sodvinImportHandler();
  };

  const customerSiteIdValidation = () => {
    if (isSodvinCompany && importParams.id === 2) {
      return true;
    } else if (importParams.id === 1) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    register('name', {
      required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string,
      maxLength: { value: 255, message: t(getTranslationKey('FORM_NAME_LENGTH_ERROR')) as string }
    });
    register('arxReferenceId', {
      required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string
    });
    register('contactPerson', {
      required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string,
      maxLength: {
        value: 255,
        message: t(getTranslationKey('FORM_CONTACT_LENGTH_ERROR')) as string
      }
    });
    register('address', {
      required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string,
      maxLength: {
        value: 1024,
        message: t(getTranslationKey('FORM_ADDRESS_LENGTH_ERROR')) as string
      }
    });
    register('email', {
      required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string,
      pattern: {
        value: Validator.validEmailRegEx,
        message: t(getTranslationKey('FORM_EMAIL_INVALID_ERROR')) as string
      },
      maxLength: { value: 255, message: t(getTranslationKey('FORM_EMAIL_LENGTH_ERROR')) as string }
    });
    register('phoneNumber', {
      required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string,
      pattern: {
        value: Validator.validPhoneNumberRegEx,
        message: t(getTranslationKey('FORM_PHONE_INVALID_ERROR')) as string
      },
      maxLength: {
        value: 20,
        message: t(getTranslationKey('FORM_PHONE_NUMBER_LENGTH_ERROR')) as string
      }
    });
    register('supportPhoneNumber');
    register('status', {
      required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string
    });
    register('profilePic');
    register('maximumNegativeBalance', {
      validate: {
        good: (value) => {
          if (isNaN(value) || +value > 0)
            return t(getTranslationKey('FORM_MAXIMUM_NEGATIVE_BALANCE_ERROR')) as string;
          return true;
        }
      }
    });
    register('kisiApiKey');
    register('kisiNightlySyncApiKey');
    register('providerId', { required: true });
    register('customerSiteId', {
      required: customerSiteIdValidation()
    });
    register('lastImportedDate');
  }, [register]);

  useEffect(() => {
    if (imgUploadStatus === 'INPROGRESS') {
      setSubmitDisabled(true);
    } else if (imgUploadStatus === 'SUCCESS') {
      setSubmitDisabled(false);
    } else if (imgUploadStatus === 'NONE') {
      setSubmitDisabled(false);
    }
  }, [imgUploadStatus]);

  const resetForm = () => {
    setImported(false);
    setNotImportedError(false);
    setImgUploadStatus('NONE');
    setFormValues(defaultValues);
    setImportAPIValue(undefined);
    reset(defaultValues);
  };

  const mindbodyImportHandler = async () => {
    try {
      const res = await (API.graphql({
        query: importSite,
        variables: {
          /** Customer Site Id */
          siteId: importAPIValue,
          provider: importParams.provider,
          checkWithDB: true
        }
      }) as Promise<{ data: { importSite: ImportSiteResponse } }>);

      if (res.data.importSite.error && res.data.importSite.errorMsg === 'SITE_ALREADY_EXISTS') {
        setAlreadyImportedError(true);
        setImporting(false);
        resetForm();
        return;
      }

      if (!res.data || res.data.importSite.error || res.data.importSite === null) {
        setImportError(true);
        setImporting(false);
        resetForm();
        return;
      }

      const importedSite = res.data.importSite.Sites && res.data.importSite.Sites[0];
      if (importedSite === null || importedSite === undefined) {
        console.log('Mindbody Import error', res);
        setImportError(true);
        resetForm();
      } else {
        setValue('providerId', 1);
        setValue('customerSiteId', importAPIValue);
        setValue('lastImportedDate', new Date().toLocaleString());
        const updatedFormVals = formValues;
        if (importedSite.ContactEmail) {
          updatedFormVals.email = importedSite.ContactEmail;
          setValue('email', importedSite.ContactEmail);
        }
        if (importedSite.Name) {
          updatedFormVals.name = importedSite.Name;
          setValue('name', importedSite.Name);
        }
        if (importedSite.LogoUrl) {
          setValue('profilePic', importedSite.LogoUrl);
          updatedFormVals.profilePic = importedSite.LogoUrl;
        }
        setFormValues(updatedFormVals);
        setImported(true);
      }
      setImporting(false);
    } catch (e) {
      setImportError(true);
      setImporting(false);
      resetForm();
      console.log(e);
    }
  };

  const sodvinImportHandler = async () => {
    const importLocationId = importAPIValue?.toString();

    if (importLocationId === '') {
      setImporting(false);
      resetForm();
      return;
    }

    try {
      const res = await (API.graphql({
        query: importSodvinLocation,
        variables: {
          sodvinCompanyId: importLocationId
        }
      }) as Promise<{ data: { importSodvinLocation: SodvinImportedLocation } }>);

      if (
        res.data.importSodvinLocation.error &&
        res.data.importSodvinLocation.errorMsg === 'SITE_ALREADY_EXISTS'
      ) {
        setAlreadyImportedError(true);
        setImporting(false);
        resetForm();
        return;
      }

      if (
        res.data.importSodvinLocation.error ||
        !res.data.importSodvinLocation.organizationNumber
      ) {
        console.log('Sodvin Import error', res);
        setImportError(true);
        setImporting(false);
        resetForm();
        return;
      } else {
        const importedLocation = res.data.importSodvinLocation;
        setValue('providerId', 2);
        setValue('customerSiteId', importAPIValue);
        setValue('lastImportedDate', new Date().toLocaleString());

        const updatedFormVals = formValues;
        if (importedLocation.email) {
          updatedFormVals.email = importedLocation.email;
          setValue('email', importedLocation.email);
        }
        if (importedLocation.telephone) {
          setValue('phoneNumber', importedLocation.telephone);
          updatedFormVals.phoneNumber = importedLocation.telephone;
        }
        if (importedLocation.name) {
          updatedFormVals.name = importedLocation.name;
          setValue('name', importedLocation.name);
        }
        if (importedLocation.address1) {
          setValue(
            'address',
            importedLocation.address1 +
              ', ' +
              importedLocation.address2 +
              ', ' +
              importedLocation.zip +
              ', ' +
              importedLocation.postOffice
          );
          updatedFormVals.address =
            importedLocation.address1 +
            ', ' +
            importedLocation.address2 +
            ', ' +
            importedLocation.zip +
            ', ' +
            importedLocation.postOffice;
        }
        if (importedLocation.logo) {
          const imageFile = base64toFile(
            importedLocation.logo,
            `sodvinLocationImage-${importedLocation.organizationNumber}`
          );
          getImageUrl(imageFile);
          updatedFormVals.profilePic = importedLocation.logo;
        }
        setFormValues(updatedFormVals);
        setImported(true);
      }
      setImporting(false);
    } catch (error) {
      setImportError(true);
      setImporting(false);
      resetForm();
      console.log('Sodvin Import error', error);
    }
  };

  const getMindbodyNightlySyncStatus = async () => {
    const result = await (API.graphql({
      query: mindbodyNightlySyncStatus,
      variables: {
        siteId: props.gymChain?.id
      }
    }) as Promise<{
      data: { mindbodyNightlySyncStatus: MindbodyNightlySyncStatusResponse };
    }>);
    console.log(result);
    if (result.data.mindbodyNightlySyncStatus.nightlySyncRunning) setSyncInProgress(true);
    console.log(syncInProgress);
  };

  const mindbodyNightlySyncClickHandler = async () => {
    const result = await (API.graphql({
      query: mindbodyNightlySync,
      variables: {
        siteId: props.gymChain?.id,
        syncInProgress: syncInProgress
      }
    }) as Promise<{
      data: { mindbodyNightlySync: MindbodyNightlySyncResponse };
    }>);
    console.log(result);
    if (result.data.mindbodyNightlySync.error) {
      createAlert(alertRef, {
        content: t(getTranslationKey('ALERT_NIGHTLY_SYNC_FAILURE')),
        severity: 'error'
      });
      setTimeout(() => {
        history.push(`/gymChains`);
        history.push(`/gymChains/${props.gymChain?.id}/edit`);
      }, 5000);
    } else {
      createAlert(alertRef, {
        content: t(getTranslationKey('ALERT_NIGHTLY_SYNC_SUCCESS')),
        severity: 'success'
      });
      setTimeout(() => {
        history.push(`/gymChains`);
        history.push(`/gymChains/${props.gymChain?.id}/edit`);
      }, 5000);
    }
  };
  return (
    <div className="row">
      <div className="d-flex flex-row">
        <div className="col">
          <Alert refProp={alertRef} />
        </div>
      </div>
      <div className="col-md-7">
        <div className="row">
          <div className="col">
            <form className="input-mb20" onSubmit={handleSubmit(onSubmitFn)} noValidate>
              <div className="d-flex flex-xxl-row flex-column align-items-xxl-start ">
                <div className="mr10 mb18">
                  <Dropdown
                    data-cy={'add-new-gym-chain-form-dropdown-provider'}
                    label={t(getTranslationKey('FORM_PROVIDER'))}
                    options={dropDownItems}
                    placeholder={t(getTranslationKey('FORM_PROVIDER_PH'))}
                    boldItems={true}
                    onChange={providerDropdownHandler}
                    value={importParams?.id}
                    disabled={props.type === 'modify'}
                    error={errors.providerId ? true : false}
                  />
                </div>
                <div className="mr10 flex-grow-3 flex-fill min-vw-25">
                  {props.type === 'new' && (
                    <Input
                      data-cy={'add-new-gym-chain-form-input-import-site-data'}
                      label={t(getTranslationKey('FORM_API'))}
                      placeholder={t(getTranslationKey('FORM_API_PH'))}
                      variant="basic"
                      onChange={importSiteIdHandler}
                      disabled={!isSodvinCompany && importParams.id === 2}
                      name={'customerSiteId'}
                      value={
                        formValues.customerSiteId === '0'
                          ? ''
                          : formValues.customerSiteId.toString()
                      }
                      required={customerSiteIdValidation()}
                      errorMessage={
                        notImportedError
                          ? t(getTranslationKey('FORM_NOT_IMPORTED_ERROR'))
                          : alreadyImportedError
                          ? t(getTranslationKey('FORM_ALREADY_IMPORTED_ERROR'))
                          : importError
                          ? t(getTranslationKey('FORM_IMPORT_ERROR'))
                          : errors.customerSiteId
                          ? t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR'))
                          : ''
                      }
                      error={
                        notImportedError ||
                        importError ||
                        alreadyImportedError ||
                        (errors.customerSiteId ? true : false)
                      }
                      noMarginBottom={true}
                    />
                  )}
                  {props.type === 'modify' && (
                    <Input
                      data-cy={'add-new-gym-chain-form-input-import-site-data'}
                      label={t(getTranslationKey('FORM_API'))}
                      placeholder={t(getTranslationKey('FORM_API_PH'))}
                      variant="basic"
                      onChange={importSiteIdHandler}
                      disabled={
                        (!isSodvinCompany && importParams.id === 2) || props.type === 'modify'
                      }
                      name={'customerSiteId'}
                      value={props.gymChain?.customerSiteId?.toString()}
                      required={!(!isSodvinCompany && importParams.id === 2)}
                      error={errors.customerSiteId?.types?.required ? true : false}
                    />
                  )}
                </div>
                <div className="pt0 pt28">
                  <div className="mb18">
                    <Button
                      data-cy={'add-new-gym-chain-form-btn-import-data'}
                      icon={importing ? 'pi-spinner pi-spin' : ''}
                      label={t(getTranslationKey('FORM_BTN_LABEL_IMPORT'))}
                      size={ButtonSizes.large}
                      onClick={(e) => handleImport(e)}
                      disabled={
                        (!isSodvinCompany && importParams.id === 2) ||
                        props.type === 'modify' ||
                        !importAPIValue
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="mb15">
                {importParams.id === 2 && (
                  <Checkbox
                    name="isSodvinCompany"
                    label={t(getTranslationKey('FORM_CHECKBOX_IS_SODVIN_COMPANY_LABEL'))}
                    checked={isSodvinCompany}
                    onChange={() => {
                      isSodvinCompany ? setIsSodvinCompany(false) : setIsSodvinCompany(true);
                    }}
                    disabled={importParams.id === 2 && props.type === 'modify'}
                  />
                )}
              </div>
              <Input
                data-cy={'add-new-gym-chain-form-input-name'}
                label={t(getTranslationKey('FORM_NAME'))}
                placeholder=""
                variant="basic"
                onChange={changeHandler}
                name="name"
                error={errors.name ? true : false}
                errorMessage={errors.name?.message}
                value={formValues.name}
                required={true}
              />
              <Input
                data-cy={'add-new-gym-chain-form-input-contact-person'}
                label={t(getTranslationKey('FORM_CONTACT'))}
                placeholder=""
                variant="basic"
                onChange={changeHandler}
                name="contactPerson"
                error={errors.contactPerson ? true : false}
                errorMessage={errors.contactPerson?.message}
                value={formValues.contactPerson}
                required={true}
              />
              <Input
                data-cy={'add-new-gym-chain-form-input-address'}
                label={t(getTranslationKey('FORM_ADDRESS'))}
                placeholder=""
                variant="textarea"
                onChangeTA={changeHandlerTA}
                name="address"
                error={errors.address ? true : false}
                errorMessage={errors.address?.message}
                value={formValues.address}
                required={true}
              />
              <Input
                data-cy={'add-new-gym-chain-form-input-email'}
                label={t(getTranslationKey('FORM_EMAIL'))}
                placeholder=""
                variant="basic"
                onChange={changeHandler}
                name="email"
                error={errors.email ? true : false}
                errorMessage={errors.email?.message}
                value={formValues.email}
                required={true}
              />
              <Input
                data-cy={'add-new-gym-chain-form-input-phone'}
                label={t(getTranslationKey('FORM_PHONE_NO'))}
                placeholder=""
                variant="basic"
                onChange={changeHandler}
                name="phoneNumber"
                error={errors.phoneNumber ? true : false}
                errorMessage={errors.phoneNumber?.message}
                value={formValues.phoneNumber}
                required={true}
              />
              <Input
                data-cy={'add-new-gym-chain-form-input-support-phone'}
                label={t(getTranslationKey('FORM_MAX_NEGATIVE_BALANCE'))}
                placeholder=""
                variant="basic"
                onChange={changeHandler}
                name="maximumNegativeBalance"
                value={formValues.maximumNegativeBalance.toString()}
                error={errors.maximumNegativeBalance ? true : false}
                errorMessage={errors.maximumNegativeBalance?.message}
              />
              <Input
                data-cy={'add-new-gym-chain-form-input-kisi-api-key'}
                label={t(getTranslationKey('FORM_KISI_API_KEY'))}
                placeholder=""
                variant="basic"
                onChange={changeHandler}
                name="kisiApiKey"
                value={formValues.kisiApiKey.toString()}
                error={errors.kisiApiKey ? true : false}
                errorMessage={errors.kisiApiKey?.message}
              />
              <Input
                data-cy={'add-new-gym-chain-form-input-kisi-nightly-sync-api-key'}
                label={t(getTranslationKey('FORM_KISI_NIGHTLY_SYNC_API_KEY'))}
                placeholder=""
                variant="basic"
                onChange={changeHandler}
                name="kisiNightlySyncApiKey"
                value={formValues.kisiNightlySyncApiKey.toString()}
                error={errors.kisiNightlySyncApiKey ? true : false}
                errorMessage={errors.kisiNightlySyncApiKey?.message}
              />

              <div className="mb25">
                <div className="mb10">
                  <Dropdown
                    data-cy={'add-new-gym-chain-form-dropdown-status'}
                    name="status"
                    options={dropDownItemsChips}
                    label={t(getTranslationKey('FORM_STATUS'))}
                    placeholder="Set Status"
                    value={formValues.status}
                    onChange={statusDropdownHandler}
                  />
                </div>
                {errors.status && (
                  <p className="p-error mb15">{t(getTranslationKey('FORM_STATUS_EMPTY_ERROR'))}</p>
                )}
              </div>
              <div>
                <Input
                  data-cy={'gym-chain-info-temp-form-input-arxReferenceId'}
                  name="arxReferenceId"
                  label={t(getTranslationKey('FORM_ARX_GYM_REFERENCE_ID'))}
                  value={formValues.arxReferenceId}
                  onChange={changeHandler}
                  error={errors.arxReferenceId ? true : false}
                  errorMessage={errors.arxReferenceId?.message}
                  required={true}
                />
              </div>

              <div className="d-flex flex-row mt35 btn-min-w-110">
                <Text bold={true}>
                  {submitDisabled
                    ? imgUploadStatus === 'INPROGRESS'
                      ? t(getTranslationKey('FORM_UPLOAD_INPROGRESS'))
                      : imgUploadStatus === 'ERROR'
                      ? t(getTranslationKey('FORM_UPLOAD_ERROR'))
                      : t(getTranslationKey('FORM_UPLOAD_SUCCESS'))
                    : imgUploadStatus === 'SUCCESS'
                    ? t(getTranslationKey('FORM_UPLOAD_SUCCESS'))
                    : ''}
                </Text>
              </div>
              {importParams.provider === 'mindbody' && (
                <div className="d-flex flex-column gap-3">
                  <div>
                    <Checkbox
                      name="allowConvertingAccessCards"
                      label={t(getTranslationKey('ALLOW_CONVERTING_CARD_NUMBERS_TO_ARX_FORMAT'))}
                      checked={allowConvertingAccessCards}
                      onChange={() => {
                        setAllowConvertingAccessCards(!allowConvertingAccessCards);
                      }}
                    />
                  </div>
                  <div>
                    <Checkbox
                      name="processSales"
                      label={t(getTranslationKey('PROCESS_SALES'))}
                      checked={processSales}
                      onChange={() => setProcessSale(!processSales)}
                    />
                  </div>
                  <div>
                    <Checkbox
                      name="processBookings"
                      label={t(getTranslationKey('PROCESS_BOOKINGS'))}
                      checked={processBookings}
                      onChange={() => setProcessBookings(!processBookings)}
                    />
                  </div>
                  <div>
                    <Checkbox
                      name="processAppointments"
                      label={t(getTranslationKey('PROCESS_APPOINTMENTS'))}
                      checked={processAppointments}
                      onChange={() => setProcessAppointments(!processAppointments)}
                    />
                  </div>
                  <div>
                    <Checkbox
                      name="fetchAccountBalance"
                      label={t(getTranslationKey('FETCH_ACCOUNT_BALANCE'))}
                      checked={fetchAccountBalance}
                      onChange={() => setFetchAccountBalance(!fetchAccountBalance)}
                    />
                  </div>
                </div>
              )}
              <div className="d-flex flex-row mt35 btn-min-w-110">
                <div className="mr15">
                  <Button
                    data-cy={'add-new-gym-chain-form-btn-save'}
                    icon={isInserted ? 'pi-spinner pi-spin' : ''}
                    label={t(getTranslationKey('FORM_BTN_LABEL_SAVE'))}
                    size={ButtonSizes.medium}
                    disabled={submitDisabled || isInserted}
                  />
                </div>
                <Button
                  data-cy={'add-new-gym-chain-form-btn-cancel'}
                  label={t(getTranslationKey('FORM_BTN_LABEL_CANCEL'))}
                  variant={ButtonVariants.textonly}
                  onClick={() => history.push('/gymChains')}
                  disabled={isInserted}
                />
                <ActionFailedDialog />
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="col-md-5">
        <div className="d-flex justify-content-center">
          <Upload
            data-cy={'add-new-gym-chain-form-upload-image'}
            setUploadState={setImgUploadStatus}
            uploadHandler={uploadHandler}
            name="test"
            previewImg={formValues.profilePic ? formValues.profilePic : undefined}
          />
        </div>
        {props.type === 'modify' ? (
          <div className="mt-3 d-flex justify-content-center">
            <div className="w-75">
              <Button
                data-cy={'add-new-gym-chain-form-start-nightly-sync'}
                label={t(
                  getTranslationKey(
                    syncInProgress ? 'FORM_BTN_STOP_NIGHTLY_SYNC' : 'FORM_BTN_START_NIGHTLY_SYNC'
                  )
                )}
                onClick={mindbodyNightlySyncClickHandler}
                block
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default GymChainInfoTemplate;

type GymChainTemplateType = 'new' | 'modify';

export interface GymChainInfoTemplateProps {
  /** Type of the template: new or modify */
  type: GymChainTemplateType;
  /** GymChain to modify, only for the modify scenario */
  gymChain?: GymChain;
}
